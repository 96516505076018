<template>
	<div :class="'buttons-container buttons-container-' + pageColor">
		<div
			class="buttonsContainer headerSection"
			:class="{
				active: statusIndex,
			}"
		>
			<div class="categoryHeader" @click="toggleIndex">
				<a class="index-mobile link-tab font-size-16 pa-2">
					{{ convertSlug(currentTitle) }}
				</a>
				<div class="bar-toggle-wrapper" :class="{ active: statusIndex }">
					<div class="bar-toggle"></div>
					<div class="bar-toggle"></div>
				</div>
			</div>

			<Button
				:left="true"
				:inverted="true"
				:color="pageColor"
				v-for="(child, i) in data.children"
				:href="getUrl(titleToSlug(data.title.rendered) + '/' + child.post_name)"
				:active="selectedIndexBlock === i"
				:key="i"
				@click="(e) => onSubPageClick(child.post_name, e)"
				:value="child.post_title"
				class="button-mobile"
			/>
			<Button
				v-if="addLogoutButton"
				@click="onClickLogout"
				:color="pageColor"
				value="Logout"
				class="logout"
				:loading="clickedLogout"
				:inverted="true"
			/>
		</div>
	</div>
</template>

<script>
import { login } from './authentication/Login';

export default {
	props: ['data', 'selectedIndexBlock', 'textColor', 'parentSlug', 'addLogoutButton'],
	data() {
		return {
			statusIndex: false,
			clickedLogout: false,
		};
	},
	mixins: [login],
	computed: {
		currentTitle() {
			let currentTitle;
			if (this.$route.params.subpage) {
				currentTitle = this.$route.params.subpage;
			} else {
				if (this.data.children && this.data.children.length > 0) {
					currentTitle = this.data.children[0].post_name;
				} else {
					currentTitle = this.$route.params.slug;
				}
			}
			return currentTitle;
		},
	},

	methods: {
		toggleIndex() {
			this.statusIndex = !this.statusIndex;
		},
		onSubPageClick(child_slug, e) {
			this.statusIndex = false;
			e.preventDefault();
			this.$emit('onChange', child_slug);
		},
		onClickLogout() {
			this.clickedLogout = true;
			setTimeout(
				function() {
					this.logout();
					this.goTo('Home', { login: true });
					this.clickedLogout = false;
				}.bind(this),
				2500
			);
		},
	},
};
</script>

<style lang="scss" scoped>
#app {
	.button-mobile {
		@media (max-width: $tablet-l) {
			width: 100%;
			background-color: transparent !important;
			color: #fff;
			border-color: transparent !important;
		}
	}
	.logout {
		float: right;
	}
	a {
		text-decoration: none;
	}

	.buttons-container-aili-yellow {
		@media (max-width: $tablet-l) {
			background-color: #cf6c00;
		}
	}
	.buttons-container-aili-green {
		@media (max-width: $tablet-l) {
			background-color: #00ac87;
		}
	}
	.buttons-container-aili-blue {
		@media (max-width: $tablet-l) {
			background-color: #008bbe;
		}
	}

	.buttons-container {
		height: 100%;
		top: 70px;
		left: 0;

		@media (max-width: $tablet-l) {
			position: sticky;

			width: 100%;
			z-index: 11;
			display: inline-block;
			right: 0;
			transform: none;
		}
	}

	.selected {
		pointer-events: none;
		@media (max-width: $tablet-l) {
			display: none !important;
		}
	}
	.slide-fade-enter-active {
		transition: all 0.3s ease;
	}
	.slide-fade-leave-active {
		transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
	}
	.slide-fade-enter,
	.slide-fade-leave-to {
		transform: translateX(10px);
		opacity: 0;
	}
	a {
		text-decoration: none;
	}
	header {
		color: $aili-white;
	}
	.categoryHeader {
		display: none;
		@media (max-width: $tablet-l) {
			display: flex;
			justify-content: space-between;
		}
	}
	.search {
		position: absolute;
		right: 0;
		top: $spacing-0;
	}

	.filters {
		padding: $spacing-0;
	}

	.filters a {
		margin-right: 15px;
	}
	.buttonsContainer {
		position: relative;
		padding: $spacing-0 0;
		@media (max-width: $tablet-l) {
			.search {
				display: none;
				opacity: 0;
				transition: all 0.3s;
				bottom: 4px;
				top: auto;
				background-color: transparent !important;
				width: 90%;
				left: 50%;
				transform: translateX(-50%);
			}
		}
	}

	.link-tab {
		@media (max-width: $tablet-l) {
			border-radius: 10px 0 0 10px;
			transition: transform 1s ease;
			color: $aili-white;
			writing-mode: vertical-rl;
			transform: rotate(180deg);
			background: transparent !important;
			line-height: 35px;
			transform: none;
			writing-mode: unset;
			font-size: $font-size-20 !important;
			&.index-mobile {
				display: none;
				font-size: $font-size-20 !important;
				text-transform: uppercase;
				display: block;
				color: white !important;
			}
		}
	}

	.bar-toggle-wrapper {
		height: 30px;
		width: 30px;
		margin-top: 10px;
		margin-right: 4px;
		position: relative;
		float: left;
		display: none;
		.bar-toggle {
			height: 2px;
			width: 14px;
			background: #fff;
			display: block;
			opacity: 1;
			margin-top: 0;
			transition: all 0.15s linear;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
		}
		.bar-toggle:nth-child(2) {
			transform: rotate(90deg) translate(0, 6px);
			transform-origin: 50% 0;
		}
		@media (max-width: $tablet-l) {
			display: block;
		}
	}

	.bar-toggle-wrapper.active {
		.bar-toggle:nth-child(2) {
			transform: rotate(0) translate(-50%, -50%);
		}
	}

	.buttonsContainer.active {
		@media (max-width: $tablet-l) {
			.search {
				opacity: 1;
				display: block;
			}
			.close-icon {
				display: block;
			}
		}
	}

	.headerSection {
		@media (max-width: $tablet-l) {
			.categoryHeader a {
				margin: 0;
			}
			a {
				text-transform: uppercase;
				font-size: 16px;
				margin: 4px 0;
				margin-left: -6px;
			}
			position: absolute;
			display: flex;
			flex-direction: column;
			transform: translateX(-100%);
			position: relative;
			transform: none;
			display: inline-block;
			flex-direction: unset;
			justify-content: center;
			width: 100%;
			max-height: 46px;
			padding: 0px 17px;
			padding-bottom: 0;
			transition: max-height 500ms;
			overflow: hidden;
			&.active {
				max-height: 1000px;
			}
		}
	}

	.label {
		@media (max-width: $tablet-l) {
			text-transform: uppercase;
			transition: 0.4s all;
			text-transform: none;
			font-size: $font-size-20;
			float: none;
			clear: both;
			width: 100%;
			display: inline-block;
		}
	}
	@media (max-width: $tablet-l) {
		header {
			background: #bc99d2;
			position: sticky;
			top: 70px;
			z-index: 11;
		}

		.buttonsContainer {
			.search {
				display: none;
				opacity: 0;
				transition: all 0.3s;
				bottom: 4px;
				top: auto;
				background-color: transparent !important;
				width: 90% !important;
				left: 50%;
				transform: translateX(-50%);
			}
		}

		.buttonsContainer.active {
			.search {
				opacity: 1;
				display: block;
			}
			.close-icon {
				display: block;
			}
		}
		.headerSection {
			position: absolute;
			display: flex;
			flex-direction: column;
			transform: translateX(-100%);
			padding: 0 $spacing-0;
			padding-bottom: 50px;
			@media (max-width: $tablet-l) {
				padding-bottom: 0;
			}
			@media (max-width: $mobile-m) {
				padding: 0 $spacing-0/2;
			}
			position: relative;
			transform: none;
			display: inline-block;
			flex-direction: unset;
			justify-content: center;
			width: 100%;
			max-height: 46px;
			transition: max-height 500ms;
			overflow: hidden;
		}
		.label {
			text-transform: uppercase;
			transition: 0.4s all;
			text-transform: none;
			font-size: $font-size-20;
			float: none;
			clear: both;
			width: 100%;
			display: inline-block;
		}

		.headerSection.active {
			max-height: 1000px;
		}

		.link-tab {
			border-radius: 10px 0 0 10px;
			transition: transform 1s ease;
			color: $aili-white !important;
			writing-mode: vertical-rl;
			transform: rotate(180deg);
			background: transparent !important;
			line-height: 35px;
			transform: none;
			writing-mode: unset;
			font-size: $font-size-20 !important;
		}

		.link-tab.index-mobile {
			font-size: 16px !important;
			text-transform: uppercase;
			display: block;
		}
	}
}
</style>
