<template>
	<div :class="'absolute-wrapper mobile-color-' + color" v-if="data.length">
		<div class="section" :class="{ active: statusIndex }">
			<div class="categoryHeader" @click="toggleIndex">
				<a class="label">
					<div class="index-mobile link-tab pa-2">
						{{ statusIndex ? "" : convertSlug(selectedPanel) }}
					</div>
				</a>
				<div
					class="bar-toggle-wrapper"
					:class="{ active: statusIndex }"
				>
					<div class="bar-toggle"></div>
					<div class="bar-toggle"></div>
				</div>
			</div>

			<a
				v-for="(section, i) in data"
				:key="i"
				:class="{
					label: true
				}"
				:ref="sanitizeTitle(section.section_name)"
			>
				<div
					v-if="section.status == 'public'"
					@click="onMenuClick(section.section_name, i)"
					:class="'link-tab public font-size-12 pa-2 ' + color"
				>
					{{ convertSlug(section.section_name) }}
				</div>
				<div
					v-if="section.status == 'private'"
					:class="'link-tab private font-size-12 pa-2 ' + color"
				>
					{{ convertSlug(section.section_name) }}
				</div>
			</a>
		</div>
	</div>
</template>

<script>
import * as easings from "vuetify/es5/services/goto/easing-patterns";
export default {
	props: ["data", "color"],
	data() {
		return {
			options: {
				duration: 500,
				offset: 400,
				easing: easings.easeInOutCubic
			},
			statusIndex: false
		};
	},

	mounted() {
		this.updateProperty({
			property: "selectedPanel",
			value: this.data.length > 0 ? this.data[0].section_name : ""
		});
		if (this.$vuetify.breakpoint.width > 1024) {
			this.options.offset = 20;
		}
	},
	methods: {
		onMenuClick(name) {
			if (name) {
				this.statusIndex = false;
				this.updateProperty({
					property: "selectedPanel",
					value: name
				});
				this.$vuetify.goTo(
					"#panel-" + this.sanitizeTitle(name),
					this.options
				);
			}
		},
		toggleIndex() {
			this.statusIndex = !this.statusIndex;
		}
	}
};
</script>

<style lang="scss" scoped>
.absolute-wrapper {
	position: sticky;
	height: 100%;
	top: 70px;
	left: 0;
	@media (max-width: $tablet-l) {
		display: none;
		width: 100%;
		z-index: 11;
		background: #ccf8ee;
		/* display: inline-block; */
		right: 0;
		transform: none;
	}
}
.section {
	position: absolute;
	display: flex;
	flex-direction: column;
	transform: translateX(-100%);
	@media (max-width: $tablet-l) {
		padding: 0 17px;
		position: relative;
		transform: none;
		display: inline-block;
		flex-direction: unset;
		justify-content: center;
		width: 100%;
		max-height: 46px;
		transition: max-height 250ms;
		overflow: hidden;
	}
}

.private {
	opacity: 0.6;
}

.section.active {
	max-height: 1000px;
}

#app .link-tab {
	border-radius: 10px 0 0 10px;
	transition: transform 1s ease;
	color: $aili-white;
	writing-mode: vertical-rl;
	transform: rotate(180deg);
	@media (max-width: $tablet-l) {
		background: transparent !important;
		line-height: 35px;
		transform: none;
		writing-mode: unset;
		font-size: $font-size-20 !important;
	}
}

#app .link-tab.index-mobile {
	display: none;
	font-size: $font-size-20 !important;
	text-transform: uppercase;
	@media (max-width: $tablet-l) {
		display: block;
	}
}

.animation {
	transform: translateX(100%);
}

.categoryHeader {
	display: none;
	@media (max-width: $tablet-l) {
		display: flex;
		justify-content: space-between;
	}
}

.label {
	text-transform: uppercase;
	transition: 0.4s all;
	@media (max-width: $tablet-l) {
		text-transform: none;
		font-size: $font-size-20;
		float: none;
		clear: both;
		width: 100%;
		display: inline-block;
	}
}

.mobile-color-background-aili-green {
	background: #67e6ca;
}
.mobile-color-background-aili-yellow {
	background: #f3a753;
}
.mobile-color-background-aili-pink {
	background: #de6ca0;
}
.mobile-color-background-aili-blue {
	background: #5ac2e9;
}

.bar-toggle-wrapper {
	height: 30px;
	width: 30px;
	margin-top: 10px;
	margin-right: 4px;
	position: relative;
	float: left;
	display: none;
	.bar-toggle {
		height: 2px;
		width: 14px;
		background: #fff;
		display: block;
		opacity: 1;
		margin-top: 0;
		transition: all 0.15s linear;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}
	.bar-toggle:nth-child(2) {
		transform: rotate(90deg) translate(0, 6px);
		transform-origin: 50% 0;
	}
	@media (max-width: $tablet-l) {
		display: block;
	}
}
.bar-toggle-wrapper.active {
	.bar-toggle:nth-child(2) {
		transform: rotate(0) translate(-50%, -50%);
	}
}
#app .link-tab.index-mobile {
	font-size: 16px !important;
	text-transform: uppercase;
	display: block;
}
</style>
