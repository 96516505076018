<template>
	<div>
		<Header />
		<v-fade-transition>
			<div v-if="data && !loadingData" :class="'page page-' + slug">
				<MainColumn>
					<h1
						class="color-aili-white font-size-50 title-desktop"
						v-html="data.title ? data.title.rendered : ''"
					/>

					<SubpagesButtons
						v-if="data.children"
						:data="data"
						:parentSlug="slug"
						:selectedIndexBlock="selectedIndexBlock"
						:textColor="pageColor"
						@onChange="changeSubPage"
						:addLogoutButton="data.canLogout"
					/>
					<VerticalTab v-if="tabs" :data="tabs.data" :color="'background-' + pageColor" />

					<v-fade-transition>
						<div v-if="data && data.blocks && data.blocks.length > 0" class="content-shadow parent">
							<RenderBlocks :blocks="data.blocks" />
						</div>
					</v-fade-transition>

					<section class="content-shadow children">
						<RenderBlocks :blocks="blocks" />
					</section>
				</MainColumn>
			</div>
		</v-fade-transition>
	</div>
</template>

<script>
import Header from '@/components/ui/Header.vue';
import VerticalTab from '@/components/ui/VerticalTab.vue';
import SubpagesButtons from '@/components/ui/SubpagesButtons.vue';

export default {
	components: {
		Header,
		RenderBlocks: () => import('@/components/blocks/RenderBlocks.vue'),
		SubpagesButtons,
		VerticalTab,
	},
	metaInfo() {
		if (this.seo === '404') {
			return {
				meta: [{ name: 'robots', content: 'noindex' }],
			};
		}
		if (this.seo) {
			return this.formatYoastMeta(this.seo);
		}
	},
	data() {
		return {
			slug: '',
			data: [],
			isPageLoaded: false,
			elemTop: null,
			elemBottom: null,
			selectedIndexBlock: 0,
			subpages: [],
			tabs: false,
		};
	},
	async mounted() {
		this.slug = this.$route.params.slug;
		this.getContent();
		this.updateProperty({
			property: 'loadingData',
			value: true,
		});
	},
	watch: {
		async '$route.fullPath'() {
			await this.getContent();
		},
		blocks() {
			if (this.data.children && this.data.children.length > 0) {
				this.setSchemaJson(this.data.children[this.selectedIndexBlock].qzr_schema);
				this.updateProperty({
					property: 'seo',
					value: this.data.children[this.selectedIndexBlock],
				});
			}
		},
		data(newData) {
			for (let i in newData.children) {
				let subpage = newData.children[i];
				setTimeout(() => this.subpages.push(subpage), i * 300);
			}
		},
		loginData: {
			deep: true,
			handler() {
				if (this.loginData.user) {
					this.updateProperty({
						property: 'loadingData',
						value: true,
					});
					this.getContent();
				}
			},
		},
	},
	computed: {
		blocks() {
			if (this.data.children && this.data.children.length > 0) {
				return this.data.children[this.selectedIndexBlock].blocks;
			} else {
				return [];
			}
		},
	},
	methods: {
		changeSubPage(child_slug) {
			this.data.children.map((child, i) => {
				if (child_slug == child.post_name) {
					this.selectedIndexBlock = i;
				}
			});
			this.goTo('Page', { slug: this.slug, subpage: child_slug });
			this.tabs = false;
		},
		async getContent(slug) {
			try {
				let response;
				let url = '/wp/v2/pages';
				if (this.$route.query && this.$route.query.preview) {
					response = await this.getPreview(url);
				} else {
					this.slug = slug ?? this.$route.params.slug;
					response = await this.$api.get(url, {
						params: {
							slug: this.slug,
							timestamp: Date.now(),
						},
					});
				}
				if (!response.data || response.data.length == 0) {
					this.updateProperty({
						property: 'seo',
						value: '404',
					});
					this.get404();
					return;
				}

				let page = response.data[0];
				let canLogout = false;
				if (
					page &&
					page.id &&
					page.qzr_fp_pages_ids.qzr_um_profile_page &&
					page.id == page.qzr_fp_pages_ids.qzr_um_profile_page.page_id
				) {
					if (this.loginData.user.data) {
						canLogout = true;
					} else {
						this.goTo('Home', { login: true });
					}
				}

				this.updateProperty({
					property: 'pageColor',
					value: page.color_page,
				});

				this.updateProperty({
					property: 'loadingData',
					value: false,
				});

				this.updateProperty({
					property: 'seo',
					value: page,
				});

				let children = page.children;
				this.data = {
					title: page.title,
					color_page: page.color_page,
					blocks: page.blocks,
					children: children,
					canLogout: canLogout,
				};

				let tabs;
				if (this.$route.params.subpage) {
					children.map((child, i) => {
						if (this.$route.params.subpage == child.post_name) {
							this.selectedIndexBlock = i;
						}
					});
					tabs = await this.$api.get('/qzr-data/panels/' + this.$route.params.subpage);
				} else {
					if (children && children[0] && children[0].post_name) {
						tabs = await this.$api.get('/qzr-data/panels/' + children[0].post_name);
					} else {
						tabs = false;
					}

					this.selectedIndexBlock = 0;
				}

				this.tabs = tabs;
			} catch (error) {
				this.updateProperty({
					property: 'loadingData',
					value: false,
				});
				this.get404(error);
			}
		},
	},
};
</script>

<style lang="scss" scoped>
.page {
  min-height: 100vh;
  padding-top: $menu-header;
  padding-bottom: $footer-distance;
  @media (max-width: $tablet-m) {
    padding-top: 110px;
  }
}

h2 {
  margin-bottom: $spacing-1;
  @media (max-width: $tablet-m) {
    margin-bottom: 25px;
  }
}
.title {
  padding: $spacing-0;
}
.slide-up-enter-active {
  transition: all 1s, opacity 2s ease;
}
.slide-up-leave-active {
  transition: all 1.7s cubic-bezier(1, .5, .8, 1);
}
.slide-up-enter,
.slide-leave-to {
  transform: translateY(20vh);
  opacity: 0;
}

.customListItem {
  padding: 0;
}

.parent {
  margin-bottom: $spacing-1;
}
.title-desktop {
  margin-bottom: $spacing-1;
}
.title-mobile {
  display: none;
  @media (max-width: $tablet-m) {
    display: block;
  }
}
</style>
